@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

.profile-container {
    display: flex;
    align-items: center;
    width: 1000px;
    margin: 150px auto;
    padding: 0px 10px 40px;
    padding-top: 50px;
    flex-direction: column;

    background-color: white;
    background-clip: padding-box;
    filter: drop-shadow(5px 10px 5px grey);
    overflow: hidden;
} 

.profile-container h1
{
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 40px;
    margin: 0px 0px 30px 0px;
}

p {
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    color:black;
}

#profile-editable-fields input {
    margin: 5px 0px 20px 0px;
}

.input-firstName, .input-lastName, .input-Email, .input-site, .input-phoneNumber, .input-availability, .input-notes {
    margin-bottom: 10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}

input[type=email]:focus {
    background-color: #F5F5F5;
}

.input-firstName, .input-lastName, .input-availability {
    padding-right: 600px;
}

.input-Email {
    padding-right: 660px;
}

.input-site {
    padding-right: 675px;
}

.input-phoneNumber {
    padding-right: 555px;
}

.input-notes {
    padding-right: 655px; 
}

#notes {
    resize: vertical;
    min-height: 100px;
    margin-bottom: 30px;
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    letter-spacing: 2px;
}

.availability-table {
    table-layout: fixed;
    width: 74%;
    border: 1px solid black;
    border-collapse: collapse;
    margin: 5px 0px 20px 0px;
}

.availability-table td, th {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 10px
}

#changes-button, #cancel-button, #signout-button {
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 12px 210px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
    width: 100%;
}


@media (max-width: 800px) {
    .profile-container {
        margin-top: 250px;
        zoom: 0.4;
        filter: none;
    }
}