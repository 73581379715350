h2 {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    color: black;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#reset-button {
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 12px 20px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;

    margin-top: 30px;
}
#reset-button:hover {
    background-color: #2098D1;
    color: white;
}

.error{
    margin: -10px 0px 20px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    color: rgb(241, 80, 80);
}