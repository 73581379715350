.info{
    border: solid 2px #142850;
}

#top-cell-route{
    border-top: solid 1px black;
    border-bottom: solid 0px;
}

#middle-cell-route{
    border-top: solid 0px;
    border-bottom: solid 0px;
}

#last-cell-route{
    border-top: solid 0px;
    border-bottom: solid 4px black;
}

#last-cell{
    border-bottom: solid 3px black;
}

#top-cell-route,
#middle-cell-route,
#last-cell-route{
    border-left: solid 1px black;
}
