@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;700&family=Ovo&display=swap');

.main img{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.columns a {
    text-decoration: none;
}

.home
{
    padding: 10px 10% 20px;
    padding-bottom: 30px;
    height: 500px;
    min-height: 350px;
    background-color: white;

}
.home h4{
    margin-top: 150px;
    text-align: left;
    font-weight: 600;
    color: #4EB265; 
}
.home h1{
    text-align: left;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 40px;
    color:rgb(0, 0, 0);
}

.columns {
    position: relative;
    min-height: calc(100vh - 370px);
}

.columns h1{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 24px;
    color:#0D2E46;
}
#arrow{
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 50px;
    color:#606060;
}
#column1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    float: left;
    width: 33%;
    padding: 25px 40px;
    height: 100%;
    background-color:#BDD3D2;
    overflow: hidden;
}
#column2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    float: left;
    width: 33%;
    padding: 25px 40px;
    height: 100%;
    background-color:#88B7C6;
    overflow: hidden;
}
#column3{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    float: left;
    width: 34%;
    padding: 25px 40px;
    height: 100%;
    background-color:#4EB2AC;
    overflow: hidden;
}

.row h1{
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 32px;
    color:#0D2E46;
    
}
.row p{
    text-align: left;
    /* margin-bottom: 100px; */
    color: #0D2E46;
}
.row button{
    justify-content: flex-end;
    align-items: flex-end;
    padding: 18px 28px;
    padding-bottom: 20px;
    border-radius: 20px;
    display: flex;
    align-items: left;
    overflow: hidden;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 1px;
    text-decoration: none;
    color: white;
    background-color: #0D2E46;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}
.row button:hover, .row button-fade:focus, .row button-fade:active {
    text-decoration: none;
    background-color: #748998;
    color: white;
}


.row {
    width: 100%;
    height: 35vh;
    min-height: 350px;
    position: absolute;
    bottom: 0px;
}
.row:after{
    content: "";
    display: table;
    clear: both;
}

@media screen and (max-width: 800px) {
    .column {
      width: 100%;
    }

    .row {
        zoom: 0.7;
        display: flex;
        flex-direction: column;
        height: auto;
        position: static;
    }

    #column1, #column2, #column3 {
        height: 100%;
        width: 100%;
        overflow: auto;
    }

    .home h1 {
        font-size: 14px;
    }

    .home {
        height: auto;
        min-height: 200px;
    }
  }