@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;700&family=Ovo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Component_main__12kMU img{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.Component_columns__oUsqq a {
    text-decoration: none;
}

.Component_home__34e3M
{
    padding: 10px 10% 20px;
    padding-bottom: 30px;
    height: 500px;
    min-height: 350px;
    background-color: white;

}
.Component_home__34e3M h4{
    margin-top: 150px;
    text-align: left;
    font-weight: 600;
    color: #4EB265; 
}
.Component_home__34e3M h1{
    text-align: left;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 40px;
    color:rgb(0, 0, 0);
}

.Component_columns__oUsqq {
    position: relative;
    min-height: calc(100vh - 370px);
}

.Component_columns__oUsqq h1{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 24px;
    color:#0D2E46;
}
#Component_arrow__2G-Ho{
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 50px;
    color:#606060;
}
#Component_column1__13-mo{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    float: left;
    width: 33%;
    padding: 25px 40px;
    height: 100%;
    background-color:#BDD3D2;
    overflow: hidden;
}
#Component_column2__3tN-L{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    float: left;
    width: 33%;
    padding: 25px 40px;
    height: 100%;
    background-color:#88B7C6;
    overflow: hidden;
}
#Component_column3__2w7ka{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    float: left;
    width: 34%;
    padding: 25px 40px;
    height: 100%;
    background-color:#4EB2AC;
    overflow: hidden;
}

.Component_row__3zdLg h1{
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 32px;
    color:#0D2E46;
    
}
.Component_row__3zdLg p{
    text-align: left;
    /* margin-bottom: 100px; */
    color: #0D2E46;
}
.Component_row__3zdLg button{
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    padding: 18px 28px;
    padding-bottom: 20px;
    border-radius: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: left;
            align-items: left;
    overflow: hidden;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 1px;
    text-decoration: none;
    color: white;
    background-color: #0D2E46;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: color, background-color;
}
.Component_row__3zdLg button:hover, .Component_row__3zdLg button-fade:focus, .Component_row__3zdLg button-fade:active {
    text-decoration: none;
    background-color: #748998;
    color: white;
}


.Component_row__3zdLg {
    width: 100%;
    height: 35vh;
    min-height: 350px;
    position: absolute;
    bottom: 0px;
}
.Component_row__3zdLg:after{
    content: "";
    display: table;
    clear: both;
}

@media screen and (max-width: 800px) {
    .Component_column__26jTG {
      width: 100%;
    }

    .Component_row__3zdLg {
        zoom: 0.7;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        height: auto;
        position: static;
    }

    #Component_column1__13-mo, #Component_column2__3tN-L, #Component_column3__2w7ka {
        height: 100%;
        width: 100%;
        overflow: auto;
    }

    .Component_home__34e3M h1 {
        font-size: 14px;
    }

    .Component_home__34e3M {
        height: auto;
        min-height: 200px;
    }
  }
.auth-form {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 700px;
    margin: 150px calc(50vw*1.2 - 350px) 50px;
    padding: 0px 10px 40px;
    -webkit-flex-direction: column;
            flex-direction: column;

    background-color: white;
    background-clip: padding-box;
    -webkit-filter: drop-shadow(5px 10px 5px grey);
            filter: drop-shadow(5px 10px 5px grey);
    overflow: hidden;
}

.title
{
    padding-top: 50px;
}
.title h1
{
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 40px;
    color: #0D2E46;
}

p{
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    color:black;
}

#cta-type {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 20px;
    -webkit-align-self: center;
            align-self: center;
}
#cta-type label{
    margin-left: 10px;
    margin-right: 20px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.05em;
    color: #7E7C7C;
}

.input-email{
    -webkit-justify-content: left;
            justify-content: left;
    text-align: left;
    padding-right: 440px;
    margin-bottom: 10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
.input-password{
    -webkit-justify-content: left;
            justify-content: left;
    text-align: left;
    padding-right: 395px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
input {
    padding: 12px 20px;
    margin: 8px;
    border: none;
}
input[type=text], input[type=password], input[type=email], input[type=tel], input[type=number] {
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    background-color: #F5F5F5;
}
.login-form input[type=text]:focus,
.login-form input[type=email]:focus,
.login-form input[type=password]:focus {
    border-width:0px;
    border:none;
    background-color: #D3D3D3;
}
input[type=checkbox] {
    -webkit-transform: scale(2);
    transform: scale(2);
}
input[type=radio] {
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
    margin-right: 3px;
}

.link{
    display: inline;
    margin-top: -32px;
    margin-bottom: 10px;
    padding-left: 370px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    color:black;
}

.password-security {
    margin: -10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #7E7C7C;
    display: block;
}
#password-visibility {
    margin-right: 20px;
}

#login-button {
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 12px 210px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    background-color: #0D2E46;
    color: white;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: color, background-color;
}
#login-button:hover, #signin button-fade:focus, #signin button-fade:active {
    background-color: #4EB2AC;
    color: white;
}

.error{
    margin: -10px 0px 20px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    color: rgb(241, 80, 80);
}


@media screen and (max-width: 800px) {
    .auth-form {
        zoom: 0.6;
        margin: 300px calc(50vw*2.1 - 350px) 50px;
    }

    .auth-form-container {
        width: 100vw;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        margin-left: 40px;
    }
}
.signup-form {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: 200px;
    margin-top:160px;
    padding-top: 50px; 
    padding-bottom: 30px;
    -webkit-flex-direction: column;
            flex-direction: column;

    background-color: white;
    background-clip: padding-box;
    -webkit-filter: drop-shadow(5px 10px 5px grey);
            filter: drop-shadow(5px 10px 5px grey);
    overflow: hidden;
}

#title {
    padding-top: 50px;
    line-height: 50px;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 40px;
    color: #0D2E46;
}
p{
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    color:black;
}

#cta-type {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 20px;
    -webkit-align-self: center;
            align-self: center;
}
#cta-type label{
    margin-left: 10px;
    margin-right: 20px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.05em;
    color: #7E7C7C;
}

.input-name{
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
}
#first-name{
    margin-left: 35px;
    padding-right: 80px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
#last-name{
    padding-left: 50px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
#input{
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
    margin-left: 35px;
    padding-right: 80px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
#pass-label{
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    color:black;
}

input[type=text],
input[type=tel],
input[type=email],
input[type=password],
input[type=number] {
    font-family: 'Quicksand', sans-serif;
    height: 60px;
}

input[type=text]:focus,
input[type=tel]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=number]:focus {
    border-width:0px;
    border:none;
    background-color: #D3D3D3;
}

input[type=checkbox] {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

input[type=radio] {
    -webkit-justify-content: center;
            justify-content: center;
}

.account-info {
    width: 470px;
    margin: 5px;
}

.user-name {
    width: 230px;
    display: inline;
    margin: 5px;
}

#signup-button {
    display: block;
    margin: 30px;
    overflow: hidden;
    padding: 12px 145px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    color: white;
    background-color: #0D2E46;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: color, background-color;
}
#signup-button:hover{
    background-color: #4EB2AC;
    color: white;
}

.volunteer-type{
    margin-top: 20px;
}
.volunteer-type p{
    display: -webkit-flex;
    display: flex;
    margin-left: 35px;
}
.volunteer-text{
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
    margin-left: 100px;
}
#driver-text{
    padding-left: 10px;
    margin-right: 80px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
#kitchen-text{
    padding-left: 10px;
    padding-right: 15px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}

.signup-error {
    margin: 20px 0px -10px;
    color: rgb(241, 80, 80);
    font-size: 17px;
}

.drop-down-site {
    margin-left: 0px;
    padding-right: 0px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}

#select-site {
    margin-left: 0px;
    padding-right: 0px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}

.info{
    border: solid 2px #142850;
}

#top-cell-route{
    border-top: solid 1px black;
    border-bottom: solid 0px;
}

#middle-cell-route{
    border-top: solid 0px;
    border-bottom: solid 0px;
}

#last-cell-route{
    border-top: solid 0px;
    border-bottom: solid 4px black;
}

#last-cell{
    border-bottom: solid 3px black;
}

#top-cell-route,
#middle-cell-route,
#last-cell-route{
    border-left: solid 1px black;
}

#route-sidebar, .site-manager-sidebar {
    background-color: #BDD3D2;
    margin: 0px 40px;
    padding-bottom: 20px;
    border: solid 3px black;
    width: 320px;
    z-index: 3;
    float: left;
    overflow-y: auto;
}

hr {
    border-top: 1px solid black
}

.route { 
    overflow: hidden;
    padding: 15px 20px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    background-color: #0D2E46;
    color: white;
    margin: 5px;
    width: 150px;
}

.route:hover, .route button-fade:focus, .route button-fade:active {
    background-color: #4EB2AC;
    color: white;
    cursor: pointer;
}



/* DayPicker styles */

.DayPicker {
    display: inline-block;
    font-size: 0.5em;
}

.DayPicker-wrapper {
    position: relative;

    -webkit-flex-direction: row;

            flex-direction: row;
    padding-bottom: 1em;

    -webkit-user-select: none;

    -ms-user-select: none;

    user-select: none;
}

.DayPicker-Months {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.DayPicker-Month {
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;

    -webkit-user-select: none;

    -ms-user-select: none;

    user-select: none;
}

.DayPicker-NavBar {}

.DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;

    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8B9898;
    cursor: pointer;
}

.DayPicker-NavButton:hover {
    opacity: 0.8;
}

.DayPicker-NavButton--prev {
    margin-right: 1.5em;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
    display: none;
}

.DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: left;
}

.DayPicker-Caption>div {
    font-weight: 500;
    font-size: 1.15em;
}

.DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
}

.DayPicker-WeekdaysRow {
    display: table-row;
}

.DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: #8B9898;
    text-align: center;
    font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
}

.DayPicker-Body {
    display: table-row-group;
}

.DayPicker-Week {
    display: table-row;
}

.DayPicker-Day {
    display: table-cell;
    padding: 0.5em;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
}

.DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #EAECEC;
    color: #000000;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
}

.DayPicker-Footer {
    padding-top: 0.5em;
}

.DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: #4A90E2;
    font-size: 0.875em;
    cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
    color: #D0021B;
    font-weight: 700;
}

.DayPicker-Day--outside {
    color: #8B9898;
    cursor: default;
}

.DayPicker-Day--disabled {
    color: #DCE0E0;
    cursor: default;
    /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
    background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;

    background-color: #4A90E2;
    color: #F0F8FF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #51A0FA;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
    display: inline-block;
}

.DayPickerInput-OverlayWrapper {
    position: relative;
}

.DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;

    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.site-manager-page {
  margin-bottom: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.site-manager-page-header {
  margin-bottom: 20px;
  margin-top: 150px;
}

.site-manager-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 10px 25px;
}
.print-meals {
  margin-top: 20px;
  background-color: #b7f8ac;
}

.site-manager-container input {
  padding: 10px;
  border: none;
  background: none;
  width: 100%;
}

.site-manager-container input:hover {
  padding: 10px;
  border: none;
  cursor: pointer;
}

.site-manager-container input:focus {
  outline: none !important;
  background: white;
  cursor: default;
  box-shadow: 0 0 15px #719ece;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#popup {
  overflow: hidden;
  text-align: center;
  border: none;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin: 10px;
}
#button-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#button-div button {
  margin-right: 20px;
}

.button-cancel {
  background-color: #0D2E46;
  color: white;
}

.button-cancel:hover,
.button-cancel button-fade:focus,
.button-cancel button-fade:active {
  background-color: #BDD3D2;
  color: white;
  cursor: pointer;
}

.button-submit:hover,
.button-submit button-fade:focus,
.button-submit button-fade:active {
  background-color: #BDD3D2;
  color: black;
  cursor: pointer;
}

/**************modal css*****************/

.order-modal {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  top: 20vw;
  left: 40vw;
  background-color: white;
  border: 2px solid black;
}

#order-modal-header {
  overflow: hidden;
  text-align: center;
  border: none;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin: 20px;
}

#order-modal-header button {
  overflow: hidden;
  padding: 12px 28px;
  border: none;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  transition-duration: 0.3s;
  transition-property: color, background-color;
  margin: 10px;
  width: 150px;
}

#order-modal-button:hover,
#order-modal-button button-fade:focus,
#order-modal-button button-fade:active {
  background-color: #2098d1;
  color: white;
  cursor: pointer;
}

.logging-container {
    margin: 20px 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 80px;
}

.log-input-box {
    width: 500px;
}

.input-date {
    -webkit-justify-content: left;
            justify-content: left;
    text-align: left;
    margin-bottom: 10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}

.log-input {
    width: 100%;
    background-color: #F5F5F5;
}

.input-hours {
    -webkit-justify-content: left;
            justify-content: left;
    text-align: left;
    padding-top: 10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}

#submit-button {
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 12px 210px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: color, background-color;
}

#submit-button:hover, #submit-button button-fade:focus, #submit-button button-fade:active {
    background-color: #2098D1;
    color: white;
}




@media (max-width: 800px) {
    .logging-container {
        zoom: 0.65;
    }
    .loggedHoursTable {
        zoom: 0.7;
    }

    .schedule-volunteer {
        zoom: 0.5;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }

    .schedule-volunteer-table {
        padding-top: 30px;
        zoom: 0.7;
    }
    /* CSS that should be displayed if width is equal to or less than 800px goes here */
}
.Modal {
  position: absolute;
  top: 110px;
  left: 380px;
  right: 20px;
  bottom: 30px;
  background-color: white;
  overflow: scroll;
  border: 2px solid black;
}

.Modal-client {
  position: absolute;
  top: 110px;
  left: 20px;
  right: 20px;
  bottom: 30px;
  background-color: white;
  overflow: scroll;
  border: 2px solid black;
}
.Overlay {
  border-radius: 25px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
}

#client-info-header {
  padding-bottom: 150px;
  padding-left: 10px;
  position: fixed;
  background-color: white;
  z-index: 99;
  width: 70%;
  height: 10%;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: black;
}

#client-fooddays {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

#client-frozendays {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

#client-info-body {
  padding-top: 150px;
  margin-left: 10px;
  margin-right: 10px;
}

label {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: black;
  font-size: 22px;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"] {
  width: 560px;
}

.add-table {
  table-layout: fixed;
  width: 50%;
  border: 1px solid black;
  border-collapse: collapse;
}

.add-table td,
th {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
  padding: 10px;
}

.two-column {
  display: grid;
  grid-template-columns: 570px 600px;
}

#spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.email-verification {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: 200px;
    margin-top:160px;
    padding-top: 30px; 
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    -webkit-flex-direction: column;
            flex-direction: column;

    background-color: white;
    background-clip: padding-box;
    -webkit-filter: drop-shadow(5px 10px 5px grey);
            filter: drop-shadow(5px 10px 5px grey);
    overflow: hidden;

    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    color:black;
}

#emailv-header {
    font-weight: bold;
}


@media screen and (max-width: 800px) {
    .email-verification {
        margin: 40px;
        margin-top: 200px;
    }
}
#name {
    width: 200px;
    font-size: 20px;
}

#contact {
    width: 270px;
}

#role {
    width: 130px;
}

#data, #contact, #name, #role {
    font-size: 20px;
}

#data {
    width: 100px;
}
#last-cell-route{
    border-top: solid 0px;
    border-bottom: solid 3px black;
    width: 60 px;
}

#title {
    padding-top: 50px;
    line-height: 50px;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 35px;
}

.input-container{
    margin-left: 15px;
    -webkit-justify-content: left;
            justify-content: left;
    text-align: left;
    margin-bottom: 10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}

.days-display{
	display: 'flex';
}

.days-row{
    margin-left: 25px;
	-webkit-flex-direction: row;
	        flex-direction: row;
    width: 500px;
    -webkit-justify-content: left;
            justify-content: left;
	font-size: 15px;
}
#checkbox-display{
	margin: 0px 0px 0px 10px;
}

input[type=checkbox]{
	margin: 5px 5px 0;
}

#days-text{
	font-size: 20px;
}

#submit-button {
    display: block;
    margin: 10px auto;
}

.checkbox-error {
    margin-top: 20px;
    font-size: 15px;
    color: red;
}

.comments {
    width: 600px;
}

.comment-box {
    width: 500px;
    margin: 20px 30px 00px 50px;
    display: -webkit-flex;
    display: flex;
    padding: 10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 2px;
    color: #7E7C7C;
}

.addPerson label {
    margin-top: 15px;
}
h2 {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    color: black;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

#reset-button {
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 12px 20px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: color, background-color;

    margin-top: 30px;
}
#reset-button:hover {
    background-color: #2098D1;
    color: white;
}

.error{
    margin: -10px 0px 20px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    color: rgb(241, 80, 80);
}
.App {
  background-color: white;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  
  font-size: calc(10px + 2vmin);
  color: black;
  zoom: 0.8;
}

.App-link {
  color: #61dafb;
}

.navbar {
  position: fixed;
  z-index: 3;
  background-color: white;
  top: 0px;
  padding-top: 10px;
  width: 100%;
  height: 120px;
}

.navbar-left {
  float: left;
  padding-left: 50px;
}

.navbar-right {
 float: right;
 padding-right: 50px;
}

.navbar h2{
  font-family: 'Sophia Pro Medium', sans-serif;
  font-weight: 400;
  font-size: 30px;
  color: black;
}

.navbar-link{
   display: inline-block;
   text-align: center;
   padding: 14px;
   color: rgb(0, 0, 0);
   text-decoration: none;
   font-size: 20px;
   font-weight: bolder;
   height: "35px";
}

.first-navbar-link{
  display: inline-block;
  text-align: center;
  padding: 14px;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 20px;
  font-weight: bolder;
  height: "35px";
  margin-left: 100px;
}

.navbar-icon {
  height: 35px;
}

.navbar-logo {
  height: 100px;
}

@media (max-width: 800px) {
  .navbar-link, .first-navbar-link {
    font-size: 12px;
  }

  .navbar-icon {
    height: 25px;
  }

  .navbar-logo {
    height: 50px;
  }
  
  .first-navbar-link {
    margin-left: 20px;
  }

  .navbar-left {
    padding-left: 20px;
  }
}

.generic-button {
  overflow: hidden;
  padding: 15px 30px;
  border: none;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 2px;
  background-color: #0D2E46;
  color: white;
}
.generic-button:hover, .generic-button button-fade:focus, .generic-button button-fade:active {
  background-color: #4EB2AC;
  color: white;
  cursor: pointer;
}
.profile-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 1000px;
    margin: 150px auto;
    padding: 0px 10px 40px;
    padding-top: 50px;
    -webkit-flex-direction: column;
            flex-direction: column;

    background-color: white;
    background-clip: padding-box;
    -webkit-filter: drop-shadow(5px 10px 5px grey);
            filter: drop-shadow(5px 10px 5px grey);
    overflow: hidden;
} 

.profile-container h1
{
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 40px;
    margin: 0px 0px 30px 0px;
}

p {
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    color:black;
}

#profile-editable-fields input {
    margin: 5px 0px 20px 0px;
}

.input-firstName, .input-lastName, .input-Email, .input-site, .input-phoneNumber, .input-availability, .input-notes {
    margin-bottom: 10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}

input[type=email]:focus {
    background-color: #F5F5F5;
}

.input-firstName, .input-lastName, .input-availability {
    padding-right: 600px;
}

.input-Email {
    padding-right: 660px;
}

.input-site {
    padding-right: 675px;
}

.input-phoneNumber {
    padding-right: 555px;
}

.input-notes {
    padding-right: 655px; 
}

#notes {
    resize: vertical;
    min-height: 100px;
    margin-bottom: 30px;
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    letter-spacing: 2px;
}

.availability-table {
    table-layout: fixed;
    width: 74%;
    border: 1px solid black;
    border-collapse: collapse;
    margin: 5px 0px 20px 0px;
}

.availability-table td, th {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 10px
}

#changes-button, #cancel-button, #signout-button {
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 12px 210px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: color, background-color;
    width: 100%;
}


@media (max-width: 800px) {
    .profile-container {
        margin-top: 250px;
        zoom: 0.4;
        -webkit-filter: none;
                filter: none;
    }
}
