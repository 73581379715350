.site-manager-page {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.site-manager-page-header {
  margin-bottom: 20px;
  margin-top: 150px;
}

.site-manager-container {
  display: flex;
  flex-direction: row;
  padding: 10px 25px;
}
.print-meals {
  margin-top: 20px;
  background-color: #b7f8ac;
}

.site-manager-container input {
  padding: 10px;
  border: none;
  background: none;
  width: 100%;
}

.site-manager-container input:hover {
  padding: 10px;
  border: none;
  cursor: pointer;
}

.site-manager-container input:focus {
  outline: none !important;
  background: white;
  cursor: default;
  box-shadow: 0 0 15px #719ece;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#popup {
  overflow: hidden;
  text-align: center;
  border: none;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin: 10px;
}
#button-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

#button-div button {
  margin-right: 20px;
}

.button-cancel {
  background-color: #0D2E46;
  color: white;
}

.button-cancel:hover,
.button-cancel button-fade:focus,
.button-cancel button-fade:active {
  background-color: #BDD3D2;
  color: white;
  cursor: pointer;
}

.button-submit:hover,
.button-submit button-fade:focus,
.button-submit button-fade:active {
  background-color: #BDD3D2;
  color: black;
  cursor: pointer;
}

/**************modal css*****************/

.order-modal {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 20vw;
  left: 40vw;
  background-color: white;
  border: 2px solid black;
}

#order-modal-header {
  overflow: hidden;
  text-align: center;
  border: none;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin: 20px;
}

#order-modal-header button {
  overflow: hidden;
  padding: 12px 28px;
  border: none;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  margin: 10px;
  width: 150px;
}

#order-modal-button:hover,
#order-modal-button button-fade:focus,
#order-modal-button button-fade:active {
  background-color: #2098d1;
  color: white;
  cursor: pointer;
}
