#route-sidebar, .site-manager-sidebar {
    background-color: #BDD3D2;
    margin: 0px 40px;
    padding-bottom: 20px;
    border: solid 3px black;
    width: 320px;
    z-index: 3;
    float: left;
    overflow-y: auto;
}

hr {
    border-top: 1px solid black
}

.route { 
    overflow: hidden;
    padding: 15px 20px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    background-color: #0D2E46;
    color: white;
    margin: 5px;
    width: 150px;
}

.route:hover, .route button-fade:focus, .route button-fade:active {
    background-color: #4EB2AC;
    color: white;
    cursor: pointer;
}


