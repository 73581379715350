@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

.signup-form {
    display: flex;
    align-items: center;
    margin: 200px;
    margin-top:160px;
    padding-top: 50px; 
    padding-bottom: 30px;
    flex-direction: column;

    background-color: white;
    background-clip: padding-box;
    filter: drop-shadow(5px 10px 5px grey);
    overflow: hidden;
}

#title {
    padding-top: 50px;
    line-height: 50px;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 40px;
    color: #0D2E46;
}
p{
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    color:black;
}

#cta-type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    align-self: center;
}
#cta-type label{
    margin-left: 10px;
    margin-right: 20px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.05em;
    color: #7E7C7C;
}

.input-name{
    display: flex;
    margin-top: 20px;
}
#first-name{
    margin-left: 35px;
    padding-right: 80px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
#last-name{
    padding-left: 50px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
#input{
    display: flex;
    margin-top: 20px;
    margin-left: 35px;
    padding-right: 80px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
#pass-label{
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    color:black;
}

input[type=text],
input[type=tel],
input[type=email],
input[type=password],
input[type=number] {
    font-family: 'Quicksand', sans-serif;
    height: 60px;
}

input[type=text]:focus,
input[type=tel]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=number]:focus {
    border-width:0px;
    border:none;
    background-color: #D3D3D3;
}

input[type=checkbox] {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

input[type=radio] {
    justify-content: center;
}

.account-info {
    width: 470px;
    margin: 5px;
}

.user-name {
    width: 230px;
    display: inline;
    margin: 5px;
}

#signup-button {
    display: block;
    margin: 30px;
    overflow: hidden;
    padding: 12px 145px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    color: white;
    background-color: #0D2E46;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}
#signup-button:hover{
    background-color: #4EB2AC;
    color: white;
}

.volunteer-type{
    margin-top: 20px;
}
.volunteer-type p{
    display: flex;
    margin-left: 35px;
}
.volunteer-text{
    display: flex;
    margin-top: 20px;
    margin-left: 100px;
}
#driver-text{
    padding-left: 10px;
    margin-right: 80px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
#kitchen-text{
    padding-left: 10px;
    padding-right: 15px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}

.signup-error {
    margin: 20px 0px -10px;
    color: rgb(241, 80, 80);
    font-size: 17px;
}

.drop-down-site {
    margin-left: 0px;
    padding-right: 0px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}

#select-site {
    margin-left: 0px;
    padding-right: 0px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
