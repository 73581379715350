@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');


.auth-form {
    display: flex;
    align-items: center;
    width: 700px;
    margin: 150px calc(50vw*1.2 - 350px) 50px;
    padding: 0px 10px 40px;
    flex-direction: column;

    background-color: white;
    background-clip: padding-box;
    filter: drop-shadow(5px 10px 5px grey);
    overflow: hidden;
}

.title
{
    padding-top: 50px;
}
.title h1
{
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 40px;
    color: #0D2E46;
}

p{
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    color:black;
}

#cta-type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    align-self: center;
}
#cta-type label{
    margin-left: 10px;
    margin-right: 20px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.05em;
    color: #7E7C7C;
}

.input-email{
    justify-content: left;
    text-align: left;
    padding-right: 440px;
    margin-bottom: 10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
.input-password{
    justify-content: left;
    text-align: left;
    padding-right: 395px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    color: #7E7C7C;
}
input {
    padding: 12px 20px;
    margin: 8px;
    border: none;
}
input[type=text], input[type=password], input[type=email], input[type=tel], input[type=number] {
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    background-color: #F5F5F5;
}
.login-form input[type=text]:focus,
.login-form input[type=email]:focus,
.login-form input[type=password]:focus {
    border-width:0px;
    border:none;
    background-color: #D3D3D3;
}
input[type=checkbox] {
    -webkit-transform: scale(2);
    transform: scale(2);
}
input[type=radio] {
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
    margin-right: 3px;
}

.link{
    display: inline;
    margin-top: -32px;
    margin-bottom: 10px;
    padding-left: 370px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    color:black;
}

.password-security {
    margin: -10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #7E7C7C;
    display: block;
}
#password-visibility {
    margin-right: 20px;
}

#login-button {
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 12px 210px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    background-color: #0D2E46;
    color: white;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}
#login-button:hover, #signin button-fade:focus, #signin button-fade:active {
    background-color: #4EB2AC;
    color: white;
}

.error{
    margin: -10px 0px 20px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    color: rgb(241, 80, 80);
}


@media screen and (max-width: 800px) {
    .auth-form {
        zoom: 0.6;
        margin: 300px calc(50vw*2.1 - 350px) 50px;
    }

    .auth-form-container {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 40px;
    }
}