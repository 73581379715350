.App {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  
  font-size: calc(10px + 2vmin);
  color: black;
  zoom: 0.8;
}

.App-link {
  color: #61dafb;
}

.navbar {
  position: fixed;
  z-index: 3;
  background-color: white;
  top: 0px;
  padding-top: 10px;
  width: 100%;
  height: 120px;
}

.navbar-left {
  float: left;
  padding-left: 50px;
}

.navbar-right {
 float: right;
 padding-right: 50px;
}

.navbar h2{
  font-family: 'Sophia Pro Medium', sans-serif;
  font-weight: 400;
  font-size: 30px;
  color: black;
}

.navbar-link{
   display: inline-block;
   text-align: center;
   padding: 14px;
   color: rgb(0, 0, 0);
   text-decoration: none;
   font-size: 20px;
   font-weight: bolder;
   height: "35px";
}

.first-navbar-link{
  display: inline-block;
  text-align: center;
  padding: 14px;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 20px;
  font-weight: bolder;
  height: "35px";
  margin-left: 100px;
}

.navbar-icon {
  height: 35px;
}

.navbar-logo {
  height: 100px;
}

@media (max-width: 800px) {
  .navbar-link, .first-navbar-link {
    font-size: 12px;
  }

  .navbar-icon {
    height: 25px;
  }

  .navbar-logo {
    height: 50px;
  }
  
  .first-navbar-link {
    margin-left: 20px;
  }

  .navbar-left {
    padding-left: 20px;
  }
}

.generic-button {
  overflow: hidden;
  padding: 15px 30px;
  border: none;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 2px;
  background-color: #0D2E46;
  color: white;
}
.generic-button:hover, .generic-button button-fade:focus, .generic-button button-fade:active {
  background-color: #4EB2AC;
  color: white;
  cursor: pointer;
}